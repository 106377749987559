exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/aboutUs.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contactUs.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-templates-author-jsx": () => import("./../../../src/templates/author.jsx" /* webpackChunkName: "component---src-templates-author-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/blog-post.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-category-jsx": () => import("./../../../src/templates/category.jsx" /* webpackChunkName: "component---src-templates-category-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */)
}

